/* istanbul ignore file */
import styled from 'styled-components';

import { Theme } from '../../theme';
import { StoryBoxGroup } from '../StoryBoxGroup';

export const StyledStoryBoxGroup = styled(StoryBoxGroup)<{
    $isFiltersApplied?: boolean;
}>`
    .story-box-list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;

        ${({ theme }: { theme: Theme }) => theme.breakpoints.up('mobile')} {
            grid-template-columns: repeat(6, 1fr);
            gap: 4rem 2rem;

            .story-box-item {
                grid-column: span 2;
            }

            .story-box-item:nth-child(1),
            .story-box-item:nth-child(2) {
                grid-column: ${({ $isFiltersApplied }) =>
                    $isFiltersApplied ? 'span 2' : 'span 3'};
            }
        }
    }
`;
