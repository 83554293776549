import styled from 'styled-components';

import { palette } from '../../theme';

export const StyledBreadcrumbs = styled.nav`
    max-width: 127.9rem;
    margin: 0 auto 3rem;
    padding: 2rem 3rem 0;
    font-weight: 700;
    font-size: 1.4rem;
`;
export const StyledList = styled.ol`
    margin: 0;
    padding-left: 0;
    line-height: 2rem;
    list-style: none;
`;

export const StyledListItem = styled.li`
    display: inline;

    + li::before {
        display: inline-block;
        margin: 0 1rem;
        color: ${palette.primary.main};
        font-size: inherit;
        content: '/';
    }

    a {
        color: ${palette.common.blue};
    }
`;
