import { Link } from '@material-ui/core';
import styled from 'styled-components';

import { palette, Theme } from '../../theme';
import { Heading } from '../Heading';

export const StyledCategoriesWrapper = styled.nav`
    display: flex;
    gap: 0.8rem;
    margin: 3rem 0;
    padding: 0.2rem 0;
    overflow-x: auto;
    white-space: nowrap;
    user-select: none;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        flex-wrap: wrap;
        gap: 2.4rem;
        justify-content: center;
        max-width: 100%;
        margin: 4rem auto;
        overflow-x: hidden;
        white-space: normal;
    }
`;

export const StyledCategoryBox = styled(Link)`
    flex: 0 0 auto;
    padding: 1rem 2rem;
    background-color: ${palette.grey[900]};
    border-style: none;
    border-radius: 2.5rem;
    &:hover {
        text-decoration: none;
        background-color: #bacfe3;
    }
    ${({ theme }: { theme: Theme }) => theme.breakpoints.down(767)} {
        &:first-child {
            margin-left: 1.5rem;
        }
        &:last-child {
            margin-right: 1.5rem;
        }
    }
`;

export const StyledHeading = styled(Heading)`
    align-content: center;
    color: ${palette.common.blue};
    font-size: 1.6rem;
    text-transform: lowercase;
`;
