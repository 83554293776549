import { v4 as uuidv4 } from 'uuid';

import {
    StyledCategoriesWrapper,
    StyledCategoryBox,
    StyledHeading,
} from './BlogCategories.styled';
import { BlogCategoriesProperties } from './BlogCategories.types';

export const BlogCategories = ({ categories }: BlogCategoriesProperties) => {
    return (
        <StyledCategoriesWrapper>
            {categories.map(category => (
                <StyledCategoryBox key={uuidv4()} href={category.href}>
                    <StyledHeading gutterBottom={false} variant="h4">
                        {category.name}
                    </StyledHeading>
                </StyledCategoryBox>
            ))}
        </StyledCategoriesWrapper>
    );
};
