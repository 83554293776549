import { StyledStoryBoxGroup } from './BlogPostsList.styled';
import { BlogPostsListProperties } from './BlogPostsList.types';

export const BlogPostsList = ({
    posts,
    isFiltersApplied,
}: BlogPostsListProperties) => {
    return (
        <StyledStoryBoxGroup
            data={posts}
            $isFiltersApplied={isFiltersApplied}
        />
    );
};
