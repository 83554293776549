import styled from 'styled-components';

import { Theme, palette } from '../../theme';
import { ButtonBase } from '../Button';

export const StyledWrapper = styled.div`
    display: flex;
    gap: 2rem;
    max-width: 127.9rem;
    margin: 2.5rem auto;
    padding-left: 0;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('mobile')} {
        padding-left: 2.5rem;
    }
`;

export const StyledList = styled.ul`
    display: flex;
    flex-wrap: nowrap;
    gap: 1rem;
    margin: 0;
    padding: 0.5rem;
    overflow-x: auto;
    list-style: none;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('mobile')} {
        flex-wrap: wrap;
        overflow-x: visible;
    }

    li:first-of-type {
        padding-left: 2.5rem;

        ${({ theme }: { theme: Theme }) => theme.breakpoints.up('mobile')} {
            padding-left: 0;
        }
    }
`;

export const StyledButton = styled(ButtonBase)`
    height: 4.4rem;
    color: ${palette.common.blue};
    background-color: ${palette.secondary.main};
    border: 0.2rem solid ${palette.common.blue};

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        content: '';
    }

    &:hover {
        color: ${palette.common.blue};
        background-color: ${palette.secondary.main};

        &::before {
            display: block;
            background-color: rgba(0, 0, 0, 0.09);
        }
    }

    &:focus {
        color: ${palette.common.blue};
        background-color: ${palette.secondary.main};
        outline-color: ${palette.common.blue};
    }

    &:active {
        &::before {
            display: block;
            background-color: rgba(0, 0, 0, 0.2);
        }
    }
`;
