import { Icon } from '../Icon/Icon';
import { StyledButton, iconColorMap } from './IconButton.styled';
import { IconButtonProperties } from './IconButton.types';

export function IconButton({
    buttonStyle = 'primary',
    onClick,
    'aria-label': ariaLabel,
    type = 'button',
    disabled = false,
    icon,
}: IconButtonProperties) {
    return (
        <StyledButton
            aria-label={ariaLabel}
            onClick={onClick}
            type={type}
            disabled={disabled}
            $style={buttonStyle}
        >
            <Icon name={icon} color={iconColorMap[buttonStyle]} size={20} />
        </StyledButton>
    );
}

export default IconButton;
