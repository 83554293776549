import { Theme } from '@material-ui/core';
import styled from 'styled-components';

export const GridContainer = styled.div<{ height?: number }>`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    width: 100%;
    margin-bottom: 1.5rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        flex-direction: row;
        height: ${properties =>
            properties.height ? `${properties.height}px` : 'auto'};
        column-gap: 1.5rem;
    }
    & img,
    & a {
        display: block;
        flex: 1 1 0;
        width: 100%;
        height: 100%;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const StyledImageWrapper = styled.div`
    flex: 1;
`;
