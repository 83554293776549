import { useCallback, useState } from 'react';

import { palette } from '../../theme';
import { ButtonBase } from '../Button';
import { FiltersOverlay } from '../FiltersOverlay';
import { Icon } from '../Icon';
import { StyledButton, StyledList, StyledWrapper } from './BlogFilters.styled';
import { BlogFiltersProperties } from './BlogFilters.types';

export function BlogFilters({
    categoryName,
    filters,
    currentFilters,
    onRemoveFilter,
    handleSubmit,
}: BlogFiltersProperties) {
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);

    const handleFiltersOverlayClose = useCallback(
        () => setIsFiltersOpen(false),
        [],
    );
    const handleFiltersOverlayOpen = useCallback(
        () => setIsFiltersOpen(true),
        [],
    );

    return (
        <>
            <StyledWrapper>
                <StyledList>
                    <li>
                        <ButtonBase
                            type="button"
                            onClick={handleFiltersOverlayOpen}
                            text="Filter"
                            buttonStyle="primary-navy"
                            startIcon={
                                <Icon
                                    name="filters"
                                    color={palette.common.white}
                                />
                            }
                        />
                    </li>
                    {currentFilters.length > 0 && (
                        <>
                            {currentFilters.map(filter => (
                                <li key={filter}>
                                    <StyledButton
                                        onClick={() => onRemoveFilter(filter)}
                                        endIcon={
                                            <Icon
                                                name="close"
                                                size={12}
                                                color={palette.common.blue}
                                            />
                                        }
                                        type="button"
                                        text={filter}
                                    />
                                </li>
                            ))}
                        </>
                    )}
                </StyledList>
            </StyledWrapper>
            <FiltersOverlay
                category={categoryName}
                isOpen={isFiltersOpen}
                onClose={handleFiltersOverlayClose}
                onClear={async () => {
                    await handleSubmit([]);
                }}
                onSubmit={async newFilters => {
                    handleFiltersOverlayClose();
                    await handleSubmit(newFilters);
                }}
                filters={filters}
                currentFilters={currentFilters}
            />
        </>
    );
}

export default BlogFilters;
