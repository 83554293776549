export const arrayCompare = <T = any>(array1: T[], array2: T[]) => {
    if (!array1 || !array2 || array1.length !== array2.length) return false;
    const dictionary = new Set<string>();
    for (let iterator = 0; iterator < array1.length; iterator += 1) {
        const value1 = JSON.stringify(array1[iterator]);

        const value2 = JSON.stringify(array2[iterator]);

        if (!dictionary.has(value1)) dictionary.add(value1);
        if (!dictionary.has(value2)) dictionary.add(value2);
    }

    return dictionary.size === array1.length;
};
