import { Drawer } from '@material-ui/core';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';

import { ButtonBase } from '../Button';
import { IconButton } from '../IconButton';
import {
    StyledCTAs,
    StyledFiltersContainer,
    StyledForm,
    StyledHeader,
    StyledHeading,
    StyledInput,
    StyledLabel,
    StyledLabelText,
    StyledPanel,
    StyledSecondaryBaseButton,
} from './FiltersOverlay.styled';
import { FiltersOverlayProperties } from './FiltersOverlay.types';
import { arrayCompare } from '@tgg/util';

export const FiltersOverlay = ({
    category,
    filters,
    currentFilters,
    isOpen,
    onClose,
    onSubmit,
    onClear,
}: FiltersOverlayProperties) => {
    const [newFilters, setNewFilters] = useState(currentFilters);

    useEffect(() => {
        if (isOpen) {
            setNewFilters(currentFilters);
        }
    }, [isOpen, setNewFilters, currentFilters]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        onSubmit(newFilters);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value, checked },
        } = event;

        if (checked) {
            setNewFilters([...newFilters, value]);
        } else {
            setNewFilters(newFilters.filter(filter => filter !== value));
        }
    };

    const handleClearFilters = () => {
        setNewFilters([]);
        onClear();
    };

    return (
        <Drawer open={isOpen} onClose={onClose} elevation={0}>
            <StyledPanel data-testid="filter-overlay">
                <StyledHeader>
                    <StyledHeading variant="h3">
                        filters: {category}
                    </StyledHeading>
                    <IconButton
                        aria-label="Close filters overlay"
                        buttonStyle="secondary"
                        icon="close"
                        onClick={onClose}
                    />
                </StyledHeader>
                <StyledForm onSubmit={handleSubmit}>
                    <StyledFiltersContainer>
                        {[...filters]
                            .sort((a, b) => a.localeCompare(b))
                            .map(filter => {
                                const inputId = `category_${filter}`;

                                return (
                                    <StyledLabel key={filter} htmlFor={inputId}>
                                        <StyledInput
                                            id={inputId}
                                            className="visually-hidden"
                                            type="checkbox"
                                            name="filters"
                                            value={filter}
                                            onChange={handleChange}
                                            checked={newFilters.includes(
                                                filter,
                                            )}
                                        />
                                        <StyledLabelText>
                                            {filter}
                                        </StyledLabelText>
                                    </StyledLabel>
                                );
                            })}
                    </StyledFiltersContainer>

                    <StyledCTAs>
                        <StyledSecondaryBaseButton
                            type="button"
                            text="Clear"
                            buttonStyle="secondary"
                            onClick={handleClearFilters}
                            disabled={newFilters.length === 0}
                        />
                        <ButtonBase
                            type="submit"
                            text="Apply"
                            disabled={arrayCompare(currentFilters, newFilters)}
                        />
                    </StyledCTAs>
                </StyledForm>
            </StyledPanel>
        </Drawer>
    );
};
